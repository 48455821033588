<template>
  <v-container id="regular-tables" fluid class="mt-5" tag="section">
    <v-card outlined max-height="100%">
      <v-simple-table fixed-header max-height="73vh">
        <thead>
          <tr>
            <th v-for="(name,index) of columns" :key="index" :class="columns.length === index+1? 'text-center':''" class="white--text light-blue darken-1">{{name}}</th>
          </tr>
        </thead>

        <tbody>
          <template v-if="linesData.length > 0">
            <tr v-for="(lineData,index) of linesData" :key="index">
              <td v-if="switchParam" >
                 <v-checkbox v-model="lineData.spotlight" :readonly="lineData.spotlight" @mousedown="alterSwitch(lineData.id)"></v-checkbox>
              </td>
              <td :style="`width:${line.width}%;`" v-for="(line,index) of lineData.lines" :key="index" v-text="line.name"></td>
              <td> <buttons-actions @action="action($event,lineData.id)" :actionsOptions="actionsOptions" /></td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td>Nenhum registro encontrado.</td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Tables",
  components:{
    ButtonsActions: ()=> import('./ButtonsActions')
  },
  props: {
    title: String,
    columns: Array,
    linesData: Array,
    actionsOptions:Array,
    switchParam:{
      value:Boolean,
      default:false
    }
  },
  data: () => ({
    radioGroup:null
  }),
  methods:{
    action($event, id){
     const params = {
       id:id,
       type:$event
     }

     this.$emit('action', params)
    },
    alterSwitch(id){
     this.linesData.forEach(element => {
        if(element.id !== id && !element.spotlight){
          element.spotlight = false
        }else{
          element.spotlight = true
        }
     });

     this.$emit('spotlight', id)

  }
  },
   

};
</script>

<style lang="sass" scoped>
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td
    word-break: break-all !important
</style>